import React, { useState } from "react";
import Skills from "../skills/Skills";
import Modal from "react-modal";
import ModalContent from "./modal/ModalContent";

Modal.setAppElement("#root");

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    //    ABOUT
    <div className="edina_tm_about" id="about">
      <div className="container">
        <div className="about_title">
          <h3>About Me</h3>
        </div>
        <div className="content">
          <div
            className="leftpart"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <div className="info">
              <h3>
                Hey there, I'm <span>Mohamed Fazil</span>
              </h3>
              <p>
                I am an outgoing, ambitious multimedia specialist with 7 years
                of experience in the advertising, marketing, production, and
                events industries. Originally from Sri Lanka, I have had the
                privilege of working with global clients throughout my career.
                My journey began in 2014 as a freelance photographer, capturing
                captivating moments and properties in various locations. In
                2016, I expanded my skill set to include graphic and UI design,
                a passion I continue to pursue with enthusiasm. From 2019 to
                2022, I served as a Senior Digital Marketing Executive cum
                Automotive Photographer in Dubai, UAE, honing my expertise in
                multimedia production and content creation. Since 2022, I have
                embarked on a new chapter in Qatar as a Business Development
                Manager. Despite my professional endeavors, I remain committed
                to my passion for freelance photography and graphic/UI designs,
                seeking to explore new verticals and craft impactful moments
                that resonate with audiences.
              </p>
            </div>
            <div className="my_skills">
              <h3 className="title">Achievements</h3>

              <div>
                <h3> 7+</h3> <p> Years of Experience</p>
                <h3> 43+</h3> <p> Completed Projects</p>
                <h3> 24+</h3> <p> Happy Clients</p>
              </div>

              <div className="edina_tm_button">
                <button
                  type="submit"
                  className="color"
                  onClick={toggleModalOne}
                >
                  More About Me
                </button>
              </div>
            </div>
          </div>
          {/* End leftpart */}

          <div className="rightpart">
            <div className="image">
              <img src="img/thumbs/26-35.jpg" alt="thumb" />

              <div
                className="main"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "img/about/2.jpg"
                  })`,
                }}
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="200"
              ></div>

              <div
                className="experience"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="info">
                  <h3>7+ Years</h3>
                  <span>Of Experience</span>
                </div>
              </div>
            </div>
          </div>
          {/* End righttpart */}
        </div>
      </div>

      {/* Start About Details Modal */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal about-popup-wrapper"
        overlayClassName="custom-overlay "
        closeTimeoutMS={500}
      >
        <div className="edina_tm_modalbox">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="description_wrap scrollable">
              <ModalContent />
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  About Details Modal */}
    </div>
    // /ABOUT
  );
};

export default About;
