import React from "react";

const ModalOne = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/photography.jpeg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Photography</h3>
        <p>
        Photography is an amazing medium for brands to express their vision and personality. We help brands create products that are more than just pretty—they're meaningful and memorable.
          <br></br>
        I've been shooting for multiple industries for years, so I know how to make your brand stand out from the crowd. 
       Clients trust us with their most important projects because they know we'll deliver exceptional results.
        </p>
        <p>
          Most photographers love to shoot the unusual, and you don’t get much
          more unusual than These Unprecedented Times. Right now everything
          counts as out of the ordinary. There are a number of remarkable things
          about these lockdown days that are worth photographing now we can
          remember them when it is all over.
        </p>
       
        
        {/* End list */}
       
      </div>
    </div>
  );
};

export default ModalOne;
