import React from "react";

const educationContent = [
  {
    year: "2016 - 2018",
    degree: "HND.in Information Technology",
    institute: "British College of Applied Studies",
  
  },
  {
    year: "2015",
    degree: "Dip.in Web Designing",
    institute: "British College of Applied Studies",
  },
  {
    year: "2015 - 2017",
    degree: "High School (Advance Level)",
    institute: "Kattankudy Central College",
  },
 

];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img src="img/edu.png" alt="icon" />
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
