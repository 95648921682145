import React from "react";

const experienceContent = [
  {
    year: "2022 - Present",
    position: "Business Development Manager",
    compnayName: "Wuduh Technology",
  },
  {
    year: "2019 - 2022",
    position: " Digital Marketing Executive cum Automotive Photographer",
    compnayName: "Princess Car Rental ",
  },
  {
    year: "   2016 - Present",
    position: " Photo-Videographer, Graphics and UI Designer",
    compnayName: "Freelancer",
  },
  {
    year: "2014 - Present",
    position: "Co-Founder and BDM",
    compnayName: "Mr.Pro Technology",
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img src="img/exp.png" alt="icon" />
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
