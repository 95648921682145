import React from "react";

const ModalFour = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/apps.jpeg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Mobile & Web App Design</h3>
        <p>
	
	As a UX/UI designer, I am passionate about creating products that are easy to use and understand.
</p>

<p>
	I'm experienced in designing for desktop, mobile and tablet devices. I've worked on projects ranging from small websites to large-scale enterprise systems, as well as products that integrate with multiple third-party systems.

</p>
      </div>
    </div>
  );
};

export default ModalFour;
