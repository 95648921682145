import React from "react";

const ModalThree = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/branding.jpeg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Branding &amp; Design</h3>
        <p>
		I help brands build a strong foundation for their visual identity, from brand design to the creation of corporate and product logos. I also specialize in creating marketing collateral, including products, packaging, advertising and more.
	</p>
      </div>
    </div>
  );
};

export default ModalThree;
