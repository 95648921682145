import React from "react";

const ModalTwo = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/cine.jpeg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Cinematography</h3>
        
<p>Cinematography is the art of creating and capturing moving images.</p>
<p>It is a way to tell stories through film and video, using techniques such as lighting, camera angles, and movement.</p>
<p>Cinematography can be done in many different styles and genres, including action movies, documentaries, horror films, comedies and dramas.</p>
<p>I work closely with directors and other production crew members to make sure that the final film looks good and conveys its message effectively.</p>
      </div>
    </div>
  );
};

export default ModalTwo;
